import { EntityResponse, TrafficLight } from "./../types";
import { minBy, maxBy, sum, countBy, groupBy, min, max } from "lodash-es";
import {
  getFormingsByScale,
  getValuesByScale,
} from "../../AggregatedDashboard/utils";
import {
  Scale,
  TestDetails,
  TestSetsData,
  TrafficLightsDistribution,
} from "./types";
import { GenericObject } from "../../../../@types";
import { MAX_LIKERT_QUESTIONS, TEST_TYPES } from "../../constants";

export function getQuestionChoices(entityResponse: EntityResponse) {
  const answers: { key: string; name: string }[] = [];

  if (entityResponse.testSets.length) {
    const [ts1] = entityResponse.testSets;
    const valuesCount = ts1.items[0]?.values ?? 0;
    for (let i = 1; i <= valuesCount; i++) {
      const key = `v${i}`;
      answers.push({
        key,
        name: ts1.items[0][key],
      });
    }
  }

  return answers;
}

export function getQuestionsData(
  entityResponse: EntityResponse,
  isIndividualReport: boolean
) {
  const choices = getQuestionChoices(entityResponse);
  const entriesPerTestSet = entityResponse.entries.reduce<any>((acc, entry) => {
    if (!acc[entry.testSetId]) {
      acc[entry.testSetId] = {};
    }

    for (let i = 1; i <= MAX_LIKERT_QUESTIONS; i++) {
      const key = `V${i}`;
      if (!acc[entry.testSetId][key]) {
        acc[entry.testSetId][key] = [];
      }
      acc[entry.testSetId][key].push(entry[key]);
    }

    return acc;
  }, {});

  return {
    choices,
    questions: entityResponse.testSets.reduce<any>((acc, testSet) => {
      testSet.items
        .filter(item => item.type === TEST_TYPES.LIKERT)
        .forEach(qItem => {
          const tsEntries = entriesPerTestSet[testSet._id];
          const questionEntries = (tsEntries?.[qItem.dbColumn] || [])
            .filter((num?: string) => num !== undefined)
            .map((num: string) => parseInt(num, 10));
          const entries: GenericObject<number> = {
            V1: 0,
            V2: 0,
            V3: 0,
            V4: 0,
            V5: 0,
            V6: 0,
          };

          let average = 0;

          if (isIndividualReport) {
            Object.keys(entries).forEach(key => {
              const value = questionEntries[0];
              if (key === `V${value}`) {
                entries[key] = parseInt(value, 10);
              }
            });
          } else {
            const questionEntriesCountBy = countBy<number>(questionEntries);
            const allQuestionsSum = sum(questionEntries);
            average = allQuestionsSum / questionEntries.length;

            Object.keys(questionEntriesCountBy).forEach(key => {
              entries[`V${key}`] =
                (questionEntriesCountBy[key] / questionEntries.length) * 100;
            });
          }

          acc.push({
            testSetId: testSet._id,
            text: qItem.text,
            dbColumn: qItem.dbColumn,
            entries,
            average,
          });
        });

      return acc;
    }, []),
  };
}

export function getTestSetsData(entityResponse: EntityResponse) {
  const testSets = entityResponse.testSets.map(testSet => {
    const testSetName = testSet.info.headline;
    const formingsByScale = getFormingsByScale(testSet, entityResponse.entries);
    const valuesByScale = getValuesByScale(testSet, entityResponse.entries);
    const scales = testSet.scales
      .filter((sc, scIndex) => !!formingsByScale[scIndex])
      .map((scale, scaleIndex) => {
        const formings = formingsByScale[scaleIndex];
        const values = valuesByScale[scaleIndex];
        const avgForming = sum(formings) / formings.length;
        const avgValue = sum(values) / values.length;
        const minForming = min<number>(formings);
        const maxForming = max<number>(formings);

        return {
          name: scale.scaleName,
          testSetName,
          id: scale._id,
          avgValue,
          avgForming,
          minForming,
          maxForming,
          trafficLightType: getTrafficLightType(avgForming),
        };
      });

    return {
      name: testSetName,
      id: testSet._id,
      minAvg: minBy(scales, "avgValue")?.avgValue || 0,
      maxAvg: maxBy(scales, "avgValue")?.avgValue || 0,
      minAvgForming: minBy(scales, "avgForming")?.avgForming || 0,
      maxAvgForming: maxBy(scales, "avgForming")?.avgForming || 0,
      minForming: minBy(scales, "minForming")?.minForming || 0,
      maxForming: maxBy(scales, "maxForming")?.maxForming || 0,
      scales,
    };
  });

  return {
    minAvg: minBy(testSets, "minAvg")?.minAvg || 0,
    maxAvg: maxBy(testSets, "maxAvg")?.maxAvg || 0,
    minAvgForming: minBy(testSets, "minAvgForming")?.minAvgForming || 0,
    maxAvgForming: maxBy(testSets, "maxForming")?.maxAvgForming || 0,
    minForming: minBy(testSets, "minForming")?.minForming || 0,
    maxForming: maxBy(testSets, "maxForming")?.maxForming || 0,
    testSets,
  };
}

export function getTrafficLightType(value: number) {
  if (value < 1.66) {
    return TrafficLight.Red;
  } else if (value < 2.33) {
    return TrafficLight.Yellow;
  } else {
    return TrafficLight.Green;
  }
}

export function getTrafficLights(testSetsData: TestSetsData) {
  return testSetsData.testSets
    .reduce<TrafficLight[]>((acc, testSet) => {
      testSet.scales.forEach(scale => {
        acc.push(scale!.trafficLightType);
      });

      return acc;
    }, [])
    .sort(
      (a, b) => trafficLightOrder.indexOf(a) - trafficLightOrder.indexOf(b)
    );
}

export function getTrafficLightsDistribution(trafficLights: TrafficLight[]) {
  const trafficLightsCount = countBy(trafficLights);
  return trafficLights.reduce<TrafficLightsDistribution>(
    (acc, val) => {
      const percentage = (trafficLightsCount[val] / trafficLights.length) * 100;
      acc[val].percentage = percentage;
      acc[val].count = trafficLightsCount[val];
      return acc;
    },
    {
      [TrafficLight.Green]: { count: 0, percentage: 0 },
      [TrafficLight.Yellow]: { count: 0, percentage: 0 },
      [TrafficLight.Red]: { count: 0, percentage: 0 },
    }
  );
}

export function getTestDetails(testSetsData: TestSetsData) {
  return groupBy<Scale>(
    testSetsData.testSets
      .flatMap(ts => ts.scales)
      .sort(
        (a, b) =>
          trafficLightOrder.indexOf(a.trafficLightType) -
          trafficLightOrder.indexOf(b.trafficLightType)
      ),
    s => s.trafficLightType
  ) as TestDetails;
}

export const trafficLightOrder = Object.values(TrafficLight);
